@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');

:root {
  --bg-color: #1B0613;
  --main-color: #18D9C5;
  --main-color-muted: #11998B;
  --main-color-higlight: #F273C8;
}

.light:root {
  --bg-color: #f5f5f5;
  --main-color: #2C5A63;
  --main-color-muted: #39727e;
  --main-color-higlight: #BF045B;
}

body {
  margin:0 auto;
  min-height: 100vh;
  max-width:960px;
  box-sizing: border-box;
  padding: 20px;
  font-family: 'JetBrains Mono', monospace;
  background-color: var(--bg-color);
  color: var(--main-color);
  transition-duration: 250ms;
}

#root{
  display: flex;
  flex-direction: column;
}

a:link, a:visited{
  color: var(--main-color);
  text-decoration:underline;
  transition-duration: 250ms;
}

a:hover, a.active{color:var(--main-color-higlight)}

h1{
  margin:0;
  font-weight: normal;
}

h2{
  margin-top:0;
  font-weight: normal;
  font-size: 1em;
}

h4{margin-bottom:10px;}

p{
  margin-top:0;
  white-space: pre-wrap;
}

.high{
  color:var(--main-color-higlight);
  text-transform: uppercase;
}

hr{
  border:1px solid var(--main-color-muted);
}

ul{padding:0;}

/*HEADER*/
.logo{
  width: max-content;

}

header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

/*CONTENT*/

main{
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

nav{
  min-width: 150px;
}

nav .nav-link{text-decoration: none;}

main section{
  text-align: right;
  width:100%;
}

main section ul{
  list-style-type: none;
}

footer {
  position:absolute;
  bottom:30px;
}

/*PROJECTS*/

.button-link, .button-link:link, .button-link:visited{
  border: solid 1px var(--main-color);
  background-color: transparent;
  color:var(--main-color);
  border-radius: 5px;
  padding:5px;
  margin-left:10px;
  margin-bottom:20px;
  cursor:pointer;
  font-size:1em;
  font-family: inherit;
  text-decoration:none;
}

.button-link:hover{
  color:var(--main-color-higlight)
}

/*CV*/

address{
  font-style: normal;
}

li.skill{
  font-size: 0.8em;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 3px;
  margin: 5px;
  display: inline-block;
}

/*DARKMODE*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  color: transparent;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main-color-muted);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color:var(--bg-color);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 2px;
}

input:checked + .slider {
  background-color: var(--main-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/*MEDIA QUERIES*/

@media (max-width: 480px) {
  body{
    max-width:calc(100% - 30px);
    padding:15px;
  }
  main{
  display:block;
  }
  nav{
    margin-bottom:20px;
  }

}

/*PRINT CV*/
@media print
{
  @page {
    /* margin-top: 0; */
    /* margin-bottom: 0; */
  }
  body  {
    margin-top: 0;
    margin-bottom: 0;
    color:  #000 !important;
    background-color: #fff;
  }
  nav, hr, .button-link, .switch{display: none;}
  main section{text-align:left;}
  address, .experience{
    display:flex;
    justify-content: space-between;
  }
  address div, .experience div{width:45%;}
  li.skill{
    margin:5px 10px 5px 0;
    border-color:#000
  }
  .logo{
    color:var(--main-color-higlight)
  }
  header{margin-bottom: 0;}
  a:link, a:visited{
    color: #000;
    text-decoration: none;
  }
  section::after{
    content:"I authorize the processing of personal data present in the CV pursuant to Legislative Decree .Lgs. 101/2018 and of the GDPR (EU Regulation 2016/679).";
    font-size:0.8em;
    margin-top:30px;
  }
} 

/*LOADING ANIMATION*/
.loader,
.loader:before,
.loader:after {
  width: 10px;
  margin:0 auto;
  aspect-ratio: .5;
  display: grid;
  background: radial-gradient(var(--main-color) 68%,var(--main-color) 72%) center/100% 50% no-repeat;
  animation: load 1.2s infinite linear calc(var(--_s,0)*.4s);
  transform: translate(calc(var(--_s,0)*150%));
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
}

.loader:before {--_s: -1}

.loader:after  {--_s:  1}

@keyframes load {
  20% {background-position: top   }
  40% {background-position: bottom}
  60% {background-position: center}
}

/*SUSTAINABILITY*/
.carbonbadge{margin-top:30px;}
.carbonbadge span{
  color: var(--main-color)
}

.carbonbadge a:last-child{
  color: #fff;
}

.carbonbadge a:first-child{
  color: #0e11a8;
}

.carbonbadge a, .carbonbadge a:link, .carbonbadge a:visited{
  text-decoration: none;
}